<template>
  <div class="cp-weather">
    <div class="cp-weather-haeder">
      <div class="logo"></div>
      <div class="weather-info">
        <i v-if="todayWeather.cond && todayWeather.cond.unicode_d"
           class="daq-weather cp-visual-weather-icon"
           v-html="todayWeather.cond.unicode_d"></i>
        <div class="weather-text">
          <div v-if="weather.hourly_forecast"
               class="wendu">
            {{ weather.hourly_forecast[0].tmp }}℃
          </div>
          <div class="tianqi">
            {{ weatherInfo }}
          </div>
        </div>
      </div>
      <div v-if="weather.daily_forecast"
           class="week-info">
        {{ weather.daily_forecast[0].date }} {{ weather.daily_forecast[0].week }}
      </div>
    </div>
    <div class="cp-weather-body">
      <div class="cp-weather-body-item">
        <el-carousel height="100%"
                     arrow="never"
                     :interval="5000">
          <el-carousel-item v-for="item in waterinfo"
                            :key="`water-${item.id}`">
            <div class="item-box">
              <div class="item-title">
                <font>水质监测</font>
                <font>{{ item.monitorName }}</font>
              </div>
              <div class="item-big-title"
                   style="margin-top:0">
                {{ getGradeName(item.grade || 0) }}
              </div>
              <div class="item-info">
                <span class="name">高锰酸钾指数</span>
                <span class="value">{{ item.gomo || '-' }}mg/L</span>
              </div>
              <div class="item-info">
                <span class="name">氨氮指数</span>
                <span class="value">{{ item.ammonia || '-' }}mg/m³</span>
              </div>
              <div class="item-info">
                <span class="name">总磷</span>
                <span class="value">{{ item.totalPh || '-' }}mg/L</span>
              </div>
              <div class="item-info">
                <span class="name">总氮</span>
                <span class="value">{{ item.totalDhom || '-' }}mg/L</span>
              </div>
              <div class="item-info">
                <span class="name">溶解氧</span>
                <span class="value">{{ item.dissolve || '-' }}mg/L</span>
              </div>
              <div class="item-info">
                <span class="name">温度</span>
                <span class="value">{{ item.temp || '-' }}℃</span>
              </div>
              <div class="item-info">
                <span class="name">PH值</span>
                <span class="value">{{ item.phValue || '-' }}</span>
              </div>
              <div class="item-info">
                <span class="name">水中油（石油类）</span>
                <span class="value">{{ item.oilWater || '-' }}mg/L</span>
              </div>
              <div class="item-info">
                <span class="name">电导率</span>
                <span class="value">{{ item.conty || '-' }}mS/cm</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="cp-weather-body-item">
        <el-carousel height="100%"
                     arrow="never"
                     :interval="5000">
          <el-carousel-item v-for="item in weatherinfo"
                            :key="`weather-${item.id}`">
            <div class="item-box">
              <div class="item-title">
                <font>空气质量</font>
                <font>{{ item.monitorName }}</font>
              </div>
              <div class="item-big-title">
                <span>{{ item.aqi || '-' }}</span>
                <div class="kq-info">
                  {{ item.aqiGrade || '-' }}
                </div>
              </div>
              <div class="item-info">
                <span class="name">NAI</span>
                <span class="value">{{ item.nai || '-' }}个/cm3</span>
              </div>
              <div class="item-info">
                <span class="name">PM2.5</span>
                <span class="value">{{ item.pm25 || '-' }}mg/L</span>
              </div>
              <div class="item-info">
                <span class="name">PM10</span>
                <span class="value">{{ item.pm10 || '-' }}mg/L</span>
              </div>
              <div class="item-info">
                <span class="name">O3</span>
                <span class="value">{{ item.o3 || '-' }}g/L</span>
              </div>
              <div class="item-info">
                <span class="name">C0</span>
                <span class="value">{{ item.co || '-' }}g/L</span>
              </div>
              <div class="item-info">
                <span class="name">NO2</span>
                <span class="value">{{ item.no2 || '-' }}kg/m³</span>
              </div>
              <div class="item-info">
                <span class="name">SO2</span>
                <span class="value">{{ item.so2 || '-' }}kg/m³</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import scenicManagementPlatformConfig from 'scenicManagementPlatformConfig';
export default {
  name: 'Weather',
  data() {
    return {
      waterType: [
        { label: 'I类',
          value: 1 },
        { label: 'II类',
          value: 2 },
        { label: 'III类',
          value: 3 },
        { label: 'IV类',
          value: 4 },
        { label: 'V类',
          value: 5 }
      ],
      // 天气数据
      weather: {},
      // 水质信息
      waterinfo: [],
      weatherinfo: []
    };
  },
  computed: {
    todayWeather() {
      return this.weather.daily_forecast && this.weather.daily_forecast.length
        ? this.weather.daily_forecast[0]
        : {};
    },
    weatherInfo() {
      let info = `${ this.todayWeather.cond && this.todayWeather.cond.txt_d || '' }`;
      if (this.todayWeather.tmp) {
        info += ` ${ this.todayWeather.tmp.min }°~${ this.todayWeather.tmp.max }°`;
      }
      return info || '';
    }
  },
  created() {
    this.getWeathernfo();
    this.getWaterInfo();
    this.getWeather();
  },
  methods: {
    getGradeName(id) {
      let name = '';
      if (id) {
        name = this.waterType.find(v => v.value === id).label;
      }
      return name;
    },
    // 获取天气
    getWeather() {
      axios.get(`https://weather.daqsoft.com/api/v3/weather?clientId=4a1641090d&code=510704&timestamp=${ Number(new Date()) }&sign=584488EEBA66E740AA57B638B6B6A43939CE3852`).then((res) => {
        if (res.data.code === 0) {
          this.weather = res.data.data;
        }
      });
    },
    // 获取天气信息
    getWeathernfo() {
      axios.get(`${ scenicManagementPlatformConfig.water }/externalApi/envir/quality/airList?vCode=${ scenicManagementPlatformConfig.vCode }`).then((res) => {
        if (res.data.code === 0) {
          const data = res.data.datas;
          this.weatherinfo = data || [];
        }
      });
    },
    // 获取水质信息
    getWaterInfo() {
      axios.get(`${ scenicManagementPlatformConfig.water }/externalApi/envir/quality/waterList?vCode=${ scenicManagementPlatformConfig.vCode }`).then((res) => {
        if (res.data.code === 0) {
          const data = res.data.datas;
          this.waterinfo = data || [];
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'daq';  /* project id 705095 */
  src: url('//at.alicdn.com/t/font_705095_6c18erjtii3.eot');
  src: url('//at.alicdn.com/t/font_705095_6c18erjtii3.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_705095_6c18erjtii3.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_705095_6c18erjtii3.woff') format('woff'),
  url('//at.alicdn.com/t/font_705095_6c18erjtii3.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_705095_6c18erjtii3.svg#daq') format('svg');
}
.daq-weather{
  font-family:'daq'!important;
  font-size:20px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@include b(weather) {
  font-family: "微软雅黑", "Times New Roman", Arial, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", sans-serif;
  width: 100%;
  height: 100%;
  background-color: #c8ccdc;
  padding: 20px 40px;
  color: #000000;
  .el-carousel{
    height: 100%;
  }
  /deep/ .el-carousel__indicators{
    display: none;
  }
  .cp-weather-haeder{
    width: 100%;
    height: 170px;
    background-image: url('~@/assets/images/xianhai/head-bg.png');
    border-radius: 15px;
    background-size: 100% 100%;
    position: relative;
    .logo{
      width: 140px;
      height: 41px;
      background-image: url('~@/assets/images/xianhai/logo.png');
      position: absolute;
      left: 40px;
      top: 12px;
    }
    .weather-info{
      position: absolute;
      left: 40px;
      top: 65px;
      display: flex;
      align-items: center;
      color:#ffffff;
      font-size: 32px;
      .cp-visual-weather-icon{
        font-size: 65px;
        margin-right: 20px;
      }
      .wendu{
        font-size: 32px;
      }
      .tianqi{
         font-size: 28px;
      }
    }
    .week-info{
      position: absolute;
      top: 10px;
      right: 40px;
      color: #ffffff;
      font-size: 28px;
    }
  }
  .cp-weather-body{
    width: 100%;
    height: calc(100% - 198px);
    margin-top: 20px;
    display: flex;
    justify-content:space-between;
    .cp-weather-body-item{
      width: calc((100% - 20px) / 2);
      height: 100%;
      background: #ffffff;
      border-radius: 15px;
      .item-box{
        margin: 20px 20px 10px 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        .item-big-title{
          font-size: 30px;
          color: #0fd041;
          margin-top: 10px;
          display: flex;
          align-items: center;
          .kq-info{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background: #63cc7c;
            border-radius: 50%;
            padding: 10px;
            color: #ffffff;
            font-size: 30px;
            margin-left: 10px;
          }
        }
        .item-title{
          font-size: 20px;
          position: relative;
          padding-left: 10px;
          display: flex;
          justify-content: space-between;
          &:before{
            content: '';
            width: 4px;
            height: 27px;
            background-color: #4680df;
            border-radius: 4px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .item-info{
          width: 100%;
          display: flex;
          line-height: 29px;
          font-size: 17px;
          .name{
            color: #666666;
            width: 60%;
          }
        }
      }
    }
  }
}
</style>;
