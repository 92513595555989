var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cp-weather" }, [
    _c("div", { staticClass: "cp-weather-haeder" }, [
      _c("div", { staticClass: "logo" }),
      _c("div", { staticClass: "weather-info" }, [
        _vm.todayWeather.cond && _vm.todayWeather.cond.unicode_d
          ? _c("i", {
              staticClass: "daq-weather cp-visual-weather-icon",
              domProps: { innerHTML: _vm._s(_vm.todayWeather.cond.unicode_d) },
            })
          : _vm._e(),
        _c("div", { staticClass: "weather-text" }, [
          _vm.weather.hourly_forecast
            ? _c("div", { staticClass: "wendu" }, [
                _vm._v(" " + _vm._s(_vm.weather.hourly_forecast[0].tmp) + "℃ "),
              ])
            : _vm._e(),
          _c("div", { staticClass: "tianqi" }, [
            _vm._v(" " + _vm._s(_vm.weatherInfo) + " "),
          ]),
        ]),
      ]),
      _vm.weather.daily_forecast
        ? _c("div", { staticClass: "week-info" }, [
            _vm._v(
              " " +
                _vm._s(_vm.weather.daily_forecast[0].date) +
                " " +
                _vm._s(_vm.weather.daily_forecast[0].week) +
                " "
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "cp-weather-body" }, [
      _c(
        "div",
        { staticClass: "cp-weather-body-item" },
        [
          _c(
            "el-carousel",
            { attrs: { height: "100%", arrow: "never", interval: 5000 } },
            _vm._l(_vm.waterinfo, function (item) {
              return _c("el-carousel-item", { key: `water-${item.id}` }, [
                _c("div", { staticClass: "item-box" }, [
                  _c(
                    "div",
                    { staticClass: "item-title" },
                    [
                      _c("font", [_vm._v("水质监测")]),
                      _c("font", [_vm._v(_vm._s(item.monitorName))]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-big-title",
                      staticStyle: { "margin-top": "0" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.getGradeName(item.grade || 0)) + " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v("高锰酸钾指数"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.gomo || "-") + "mg/L"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("氨氮指数")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.ammonia || "-") + "mg/m³"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("总磷")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.totalPh || "-") + "mg/L"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("总氮")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.totalDhom || "-") + "mg/L"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("溶解氧")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.dissolve || "-") + "mg/L"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("温度")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.temp || "-") + "℃"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("PH值")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.phValue || "-")),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v("水中油（石油类）"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.oilWater || "-") + "mg/L"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("电导率")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.conty || "-") + "mS/cm"),
                    ]),
                  ]),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "cp-weather-body-item" },
        [
          _c(
            "el-carousel",
            { attrs: { height: "100%", arrow: "never", interval: 5000 } },
            _vm._l(_vm.weatherinfo, function (item) {
              return _c("el-carousel-item", { key: `weather-${item.id}` }, [
                _c("div", { staticClass: "item-box" }, [
                  _c(
                    "div",
                    { staticClass: "item-title" },
                    [
                      _c("font", [_vm._v("空气质量")]),
                      _c("font", [_vm._v(_vm._s(item.monitorName))]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "item-big-title" }, [
                    _c("span", [_vm._v(_vm._s(item.aqi || "-"))]),
                    _c("div", { staticClass: "kq-info" }, [
                      _vm._v(" " + _vm._s(item.aqiGrade || "-") + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("NAI")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.nai || "-") + "个/cm3"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("PM2.5")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.pm25 || "-") + "mg/L"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("PM10")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.pm10 || "-") + "mg/L"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("O3")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.o3 || "-") + "g/L"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("C0")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.co || "-") + "g/L"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("NO2")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.no2 || "-") + "kg/m³"),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("span", { staticClass: "name" }, [_vm._v("SO2")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(item.so2 || "-") + "kg/m³"),
                    ]),
                  ]),
                ]),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }